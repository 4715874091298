
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import PatientForm from '@/components/PatientForm.vue'
import { db } from '@/services/firebase'
import { Patient } from '@/types.ts'
import { getUTC } from '@/helpers/date.ts'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { PatientForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const patient = ref({})
    const patientId = route.params.id
    const breadcrumbs = [
      { label: 'Patients', to: '/patients' },
      { label: 'Update' }
    ]

    function getPatient() {
      db.ref('patients/' + patientId)
        .once('value')
        .then(snapshot => {
          if (snapshot.val())
            patient.value = { ...snapshot.val(), id: patientId }
        })
    }

    function update(formData: Patient) {
      const utc = getUTC(new Date())
      db.ref('patients/' + patientId)
        .update({ ...formData, modified: utc })
        .then(() => {
          router.push({ name: 'PatientIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Update Failed',
            detail: err.message,
            life: 3000
          })
        })
    }

    getPatient()

    return { update, patient, breadcrumbs }
  }
}
